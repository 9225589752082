<script setup lang="ts">
const { $uniqueRouteName } = useNuxtApp();
const { refreshAds } = useGptStore();

onMounted(() => {
  refreshAds();
});

const { client } = import.meta;
</script>

<template>
  <NuxtErrorBoundary>
    <div class="hay-layout--two-column">
      <div class="hay-layout__full">
        <AdvertSlot
          v-if="client"
          :id="`${$uniqueRouteName()}-topBillboard`"
          unit="topBillboard"
          class="hay-layout__advert--top"
        />
        <slot name="full" />
      </div>
      <div class="hay-layout__content">
        <main class="hay-layout__main">
          <NuxtErrorBoundary>
            <slot />
            <template #error="{ error }">
              <ErrorMessage
                v-if="error"
                class="error__message"
                :error="error"
              />
              <PageSection title="Recent Ads" :class="$buildClass('recent')">
                <RecentPosts />
              </PageSection>
            </template>
          </NuxtErrorBoundary>
        </main>
        <aside class="hay-layout__sidebar">
          <slot name="sidebar" />
          <AdvertSlot
            v-if="client"
            :id="`${$uniqueRouteName()}-sidebarHpu`"
            unit="sidebarHpu"
            class="hay-layout__advert--sidebar"
          />
        </aside>
      </div>
    </div>
  </NuxtErrorBoundary>
</template>

<style scoped lang="scss">
.hay-layout {
  $this: &;
  &--two-column {
    display: flex;
    flex-direction: column;

    @extend .hay-layout;
    #{$this}__content {
      @include container;

      margin-top: var(--content-top-margin, 0);
      padding-bottom: 16px;
      padding-top: 16px;

      display: grid;

      grid-template-columns: 1fr 300px;
      grid-column-gap: 16px;
    }
    #{$this}__main {
      @include media-breakpoint-up(lg) {
        grid-column: span 1;
      }

      overflow: hidden;

      grid-column: span 2;
    }
    #{$this}__sidebar {
      @include media-breakpoint-up(lg) {
        margin-top: 0;

        grid-column: span 1;
      }

      margin-top: 32px;

      display: flex;
      flex-direction: column;

      grid-column: span 2;
      &:deep(> * + *) {
        margin-top: 16px;
      }
    }
  }
  &__advert--top {
    @include media-breakpoint-up(md) {
      padding: 32px 16px;
    }

    padding: 16px;

    display: flex;

    justify-content: center;
  }
  &__advert--sidebar {
    align-self: center;
  }
}
</style>
